<template>
  <section class="c-offer container relative">
    <h2 class="c-offer__title headline-2">
      Наше предложение
    </h2>
    <div class="c-offer__wrap blue--bg">
      <p class="headline-3 c-offer__name">
        {{ blockOffer.title }}
      </p>
      <div
        class="c-offer__content"
        v-html="blockOffer.content"
      />
    </div>
    <d-btn
      class="c-offer__btn headline-4"
      color="primary"
      @click="scrollToForm"
    >
      Заполнить анкету
    </d-btn>
  </section>
</template>

<script setup lang="ts">
import { scrollToBlock } from '~/composible/scrollToBlock'
import { useMainStore } from '~/store/main/main.store'

const props = defineProps({
  blockOffer: {},
})

const scrollToForm = () => {
  const target = document.getElementById('c-form')
  scrollToBlock(target, 750, 0)
}
const mainStore = useMainStore()
const disabledMobile = computed(() => mainStore.disabledMobile)

onMounted(() => {
  if (!disabledMobile.value) {
    const parentTop = document.getElementsByClassName('career-job-requirements')?.[0]
    const heightTop = parentTop.getBoundingClientRect()
    parentTop.style.maxHeight = heightTop.height + 'px'
    const btnTop = parentTop?.children?.[0]
    if (btnTop) {
      parentTop.classList.add('active')
      btnTop.addEventListener('click', () => {
        if (parentTop.classList.contains('active')) parentTop.classList.remove('active')
        else parentTop.classList.add('active')
      })
    }
    const parentBottom = document.getElementsByClassName('career-job-benefits')?.[0]
    const heightBottom = parentBottom.getBoundingClientRect()
    parentBottom.style.maxHeight = heightBottom.height + 'px'
    const btnBottom = parentBottom?.children?.[0]
    if (btnBottom) {
      btnBottom.addEventListener('click', () => {
        if (parentBottom.classList.contains('active')) parentBottom.classList.remove('active')
        else parentBottom.classList.add('active')
      })
    }
  }
})
</script>

<style scoped lang="scss">
.c-offer {
  margin-bottom: 64px;
  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }

  &__wrap {
    padding: 40px;
    border: 1px solid color(gray-darker);
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.15);
    @media (max-width: getBreakpoint(tablet)) {
      padding: 32px 24px 112px 24px;
    }
  }
  &__name {
    margin-bottom: 12px;
  }

  &__btn {
    border-radius: 36px;
    padding: 20px 40px;
    position: absolute;
    right: 40px;
    bottom: 40px;
    @media (max-width: getBreakpoint(tablet)) {
      position: static;
      height: 54px;
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
      max-width: 400px;
      margin-top: -88px;

    }
  }

  &__content {
    &:deep(.career-job-lists) {
      display: flex;
      margin-bottom: 36px;
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
        margin-bottom: 24px;
      }
    }
    &:deep(.career-job-doing) {
      margin-bottom: 24px;
      li {
        margin-top: 8px;
        &:first-child {
          margin-top: 12px;
          @media (max-width: getBreakpoint(tablet)) {
            margin-top: 8px;
          }
        }
      }
    }
    &:deep(.career-job-requirements) {
      width: 400px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      @media (max-width: getBreakpoint(tablet)) {
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0;
        overflow: hidden;
        transition: max-height .24s;
        &.active {
          max-height: 46px !important;
          p:before {
            transform: translateY(-50%) rotate(-180deg) !important;
          }
        }
      }
      p {
        margin-top: 12px;
        margin-bottom: 12px;
        &:first-child {
          border-radius: 16px;
          background: rgba(29, 97, 207, 0.20);
          padding: 4px 16px;
          margin-right: auto;
          margin-top: 0;
          @media (max-width: getBreakpoint(tablet)) {
            padding: 10px 16px;
            border-radius: 12px;
            width: 100%;
            position: relative;
            &:before {
              content: url("@/assets/images/svg/arrow-expand.svg");
              position: absolute;
              right: 7px;
              height: 32px;
              top: 50%;
              transform: translateY(-50%);
              transition: transform .24s;
            }
          }
        }
      }
      li {
        padding-left: 32px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background: url("@/assets/images/useful/plus.svg");
          background-size: contain;
          width: 24px;
          height: 24px;
          left: 0;
          top: -2px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
    &:deep(.career-job-salary) {
      p:first-child {
        margin-bottom: 8px;
      }
    }
    &:deep(.career-job-benefits) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      transition: max-height .24s;
      &.active {
        max-height: 46px !important;
        p:before {
          transform: translateY(-50%) rotate(-180deg) !important;
        }
      }
      p {
        margin-top: 12px;
        margin-bottom: 12px;
        &:first-child {
          border-radius: 16px;
          background: rgba(238, 60, 107, 0.10);
          padding: 4px 16px;
          margin-right: auto;
          margin-top: 0;
          @media (max-width: getBreakpoint(tablet)) {
            padding: 10px 16px;
            border-radius: 12px;
            width: 100%;
            position: relative;
            &:before {
              content: url("@/assets/images/svg/arrow-expand.svg");
              position: absolute;
              right: 7px;
              height: 32px;
              top: 50%;
              transform: translateY(-50%);
              transition: transform .24s;
            }
          }
        }
      }
      li {
        padding-left: 32px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background: url("@/assets/images/svg/ischecked.svg");
          background-size: contain;
          width: 24px;
          height: 24px;
          left: 0;
          top: -2px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
